import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-contact-breadcrum">
        <div className="breadcrum-bg">
          <div className="container py-5">
            <p>
              <Link to="/Home">Home</Link> &nbsp; / &nbsp; About
            </p>
            <h2 className="my-3">About Us</h2>
          </div>
        </div>
      </section>

      <section className="w3l-features-1">
        {/* /features */}
        <div className="features py-5">
          <div className="container">
            <div className="fea-gd-vv row ">
              <div className="col-lg-4 col-sm-6">
                <div className="float-lt feature-gd">
                  <div className="icon">
                    {" "}
                    <span className="fa fa-bullseye" aria-hidden="true" />
                  </div>
                  <div className="icon-info">
                    <h5>Mission</h5>
                    <p className="mt-2">
                      Our continuous dedication revolves around consistently
                      exceeding customer anticipations by delivering outstanding
                      worldwide transportation and comprehensive logistical
                      solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="float-lt feature-gd">
                  <div className="icon">
                    {" "}
                    <span className="fa fa-check" aria-hidden="true" />
                  </div>
                  <div className="icon-info">
                    <h5>Objective</h5>
                    <p className="mt-2">
                      We are committed to upholding our professional identity
                      and values while providing unmatched freight services
                      worldwide, showcasing our steadfast dedication to
                      excellence.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="float-lt feature-gd">
                  <div className="icon">
                    {" "}
                    <span className="fa fa-eye" aria-hidden="true" />
                  </div>
                  <div className="icon-info">
                    <h5>Principle</h5>
                    <p className="mt-2">
                      At {companyname}, our guiding principle is an enduring
                      commitment to upholding unwavering quality standards at
                      all times.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //features */}
      </section>
      {/*  About section */}
      <div className="w3l-about1 py-5" id="about">
        <div className="container py-lg-3">
          <div className="aboutgrids row">
            <div className="col-lg-6 aboutgrid2">
              <img
                src="assets/images/About1.jpg"
                alt="about image"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 aboutgrid1 mt-lg-0 mt-4 pl-lg-5">
              <h1>About Us</h1>
              <h5 className="mt-4">Solutions for Every Challenge</h5>
              <p>
                {companyname} is a publicly traded company specializing
                in freight forwarding and trading services. Our approach is
                deeply rooted in cutting-edge technology, with a mission to
                provide customized logistic solutions to clients worldwide.
                {companyname} excels in handling a wide range of
                shipment types, including perishables, time-sensitive express
                shipments, general cargo, oversized cargo (ODC), hazardous
                materials, and refrigerated cargo, utilizing various
                transportation modes such as air, sea, rail, and road. Our
                unwavering commitment and exceptional performance have earned us
                prestigious certifications from both Indian and international
                airlines and institutions.
              </p>
             
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
