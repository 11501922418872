import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <section className="w3l-footer-29-main" id="footer">
        <div className="footer-29">
          <div className="grids-forms-1 pb-5">
            <div className="container">
              <div className="grids-forms">
                <div className="main-midd">
                  <h4 className="title-head">
                    Newsletter – Subscribe to get latest updates
                  </h4>
                </div>
                <div className="main-midd-2">
                  <form
                    className="rightside-form"
                    action="/php/thankyou-subscribe.php "
                    method="POST"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                    <input
                      onChange={(event) => handleSubemailChange(event)}
                      id="subemail"
                      value={subemail}
                      type="email"
                      name="subemail"
                      placeholder="Your email address"
                      required
                    />
                    <button
                      className="btn"
                      type="submit"
                      name="submit"
                      defaultValue="Sign up"
                    >
                      Subscribe{" "}
                    </button>
                  </form>
                  <h1>{result}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container pt-5">
            <div className="d-grid grid-col-4 footer-top-29">
              <div className="footer-list-29 footer-1">
                <h6 className="footer-title-29">About Us</h6>
                <ul>
                  <p>
                    Rockstone Enterprises is a publicly traded company
                    specializing in freight forwarding and trading services. Our
                    approach is deeply rooted in cutting-edge technology, with a
                    mission to provide customized logistic solutions to clients.
                  </p>
                </ul>
              </div>
              <div className="footer-list-29 footer-2">
                <ul>
                  <h6 className="footer-title-29">Useful Links</h6>

                  <li>
                    {" "}
                    <Link to="/Home">Home</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/About">About Us</Link>
                  </li>

                  <li>
                    {" "}
                    <Link to="/Trading">Trading Services</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Contact">Contact</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Getquote">Getquote</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Terms">Terms</Link>
                  </li>
                </ul>
              </div>

              <div className="footer-list-29 footer-4">
                <ul>
                  <h6 className="footer-title-29">Our Services</h6>
                  <li>
                    {" "}
                    <Link to="/Air"> Air Freight </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Sea"> Sea Freight </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Rail"> Rail Freight </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Surface"> Surface Freight </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Customs"> Customs Clearance </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Warehousing">Warehousing Service </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-list-29 footer-2">
                <ul>
                  <h6 className="footer-title-29">Quick Links</h6>
                  <p>
                    <strong>Address: </strong>{companyaddress}<br/>
                    <strong>Phone: </strong>{companynumber}<br/>
                    <strong>Email: </strong>{companyemail}<br/>
                    </p>
                </ul>
              </div>
            </div>
            <div className="bottom-copies text-center">
              <p className="copy-footer-29">
                © 2023 {companyname}. All rights reserved
              </p>
            </div>
          </div>
        </div>
        {/* move top */}
        <button onclick="topFunction()" id="movetop" title="Go to top">
          <span className="fa fa-angle-up" />
        </button>
        {/* /move top */}
      </section>
    </>
  );
};

export default Footer;
