import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");
  const [uorigin, setUorigin] = useState("");
  const [udestination, setUdestination] = useState("");
  const [ugoods, setUgoods] = useState("");
  const [umeasurements, setUmeasurements] = useState("");
  const [ulength, setulength] = useState("");
  const [uheight, setUheight] = useState("");
  const [uwidth, setUwidth] = useState("");
  const [uweight, setUweight] = useState("");
  const [udate, setUdate] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };
  const handleuoriginChange = (e) => {
    setUorigin(e.target.value);
  };

  const handleudestinationChange = (e) => {
    setUdestination(e.target.value);
  };
  const handlugoodsChange = (e) => {
    setUgoods(e.target.value);
  };
  const handleumeasurementsChange = (e) => {
    setUmeasurements(e.target.value);
  };
  const handleulengthChange = (e) => {
    setulength(e.target.value);
  };
  const handleuheightChange = (e) => {
    setUheight(e.target.value);
  };
  const handleuwidthChange = (e) => {
    setUwidth(e.target.value);
  };
  const handleuweightChange = (e) => {
    setUweight(e.target.value);
  };
  const handleudateChange = (e) => {
    setUdate(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <section
        className="w3l-main-slider w3-banner jarallax"
        id="home"
        style={{ paddingTop: "100px" }}
      >
        <video muted loop autoPlay>
          <source src="assets/images/video.mp4"></source>
        </video>
      </section>
      <section className="w3l-features-1">
        {/* /features */}
        <div className="features py-5">
          <div className="container">
            <div className="fea-gd-vv row ">
              <div className="col-lg-4 col-sm-6">
                <div className="float-lt feature-gd">
                  <div className="icon">
                    {" "}
                    <span className="fa fa-bullseye" aria-hidden="true" />
                  </div>
                  <div className="icon-info">
                    <h5>Mission</h5>
                    <p className="mt-2">
                      Our continuous dedication revolves around consistently
                      exceeding customer anticipations by delivering outstanding
                      worldwide transportation and comprehensive logistical
                      solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="float-lt feature-gd">
                  <div className="icon">
                    {" "}
                    <span className="fa fa-check" aria-hidden="true" />
                  </div>
                  <div className="icon-info">
                    <h5>Objective</h5>
                    <p className="mt-2">
                      We are committed to upholding our professional identity
                      and values while providing unmatched freight services
                      worldwide, showcasing our steadfast dedication to
                      excellence.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="float-lt feature-gd">
                  <div className="icon">
                    {" "}
                    <span className="fa fa-eye" aria-hidden="true" />
                  </div>
                  <div className="icon-info">
                    <h5>Principle</h5>
                    <p className="mt-2">
                      At {companyname}, our guiding principle is an enduring
                      commitment to upholding unwavering quality standards at
                      all times.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //features */}
      </section>
      {/*  About section */}
      <div className="w3l-about1 py-5" id="about">
        <div className="container py-lg-3">
          <div className="aboutgrids row">
            <div className="col-lg-6 aboutgrid2">
              <img
                src="assets/images/About1.jpg"
                alt="about image"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 aboutgrid1 mt-lg-0 mt-4 pl-lg-5">
              <h1>About Us</h1>
              <h5 className="mt-4">Solutions for Every Challenge</h5>
              <p>
              {companyname} is a publicly traded company specializing in freight forwarding and trading services. Our approach is deeply rooted in cutting-edge technology, with a mission to provide customized logistic solutions to clients worldwide. {companyname} excels in handling a wide range of shipment types, including perishables, time-sensitive express shipments, general cargo, oversized cargo (ODC), hazardous materials, and refrigerated cargo, utilizing various transportation modes such as air, sea, rail, and road. Our unwavering commitment and exceptional performance have earned us prestigious certifications from both Indian and international airlines and institutions.
              </p>
              <Link className="btn btn-secondary btn-theme2" to="/About">
                {" "}
                About Us
              </Link>
            </div>
          </div>
        </div>
      </div>

      <section className="w3l-features-8">
        {/* /features */}
        <div className="features py-5" id="features">
          <div className="container py-md-3">
            <div className="heading text-center mx-auto">
              <h3 className="head">Business Trade We Served</h3>
              <p className="my-3 head">
              
              </p>
            </div>
            <div className="fea-gd-vv text-center row mt-5 pt-3">
              <div className="col-lg-4 col-md-6">
                <div className="float-top">
                  <Link to="/Trading">
                    <img
                      src="assets/images/Apparel.jpg"
                      className="img-responsive"
                      alt=""
                    />
                  </Link>
                  <div className="float-lt feature-gd">
                    <h3>
                      <Link to="/Trading">Apparel</Link>{" "}
                    </h3>

                    <Link
                      className="btn btn-secondary btn-theme4 mt-4"
                      to="/Trading"
                    >
                      {" "}
                      More Details
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="float-top">
                  <Link to="/Trading">
                    <img
                      src="assets/images/Wood.jpg"
                      className="img-responsive"
                      alt=""
                    />
                  </Link>
                  <div className="float-lt feature-gd">
                    <h3>
                      <Link to="/Trading">Wood</Link>{" "}
                    </h3>

                    <Link
                      className="btn btn-secondary btn-theme4 mt-4"
                      to="/Trading"
                    >
                      {" "}
                      More Details
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-lg-0 mt-5">
                <div className="float-top-1 text-left pl-4 pr-4 py-5">
                  <h4 className>Contact Us</h4>
                  <p className="mt-2">
                   
                  </p>
                  <div className="top-right mt-4 pt-2">
                    <div className="icon-left pt-1">
                      <span className="fa fa-arrow-right" />
                    </div>
                    <div className="icon-text-left">
                      <h3 className>
                        <Link to="/Contact">Send Message</Link>{" "}
                      </h3>
                      <p className="mt-2">
                      Unlock Global Opportunities: Our freight forwarding services can connect your business to new markets. Fill up the contact form to explore new horizons.
                      </p>
                    </div>
                  </div>
                  <div className="top-right mt-3">
                    <div className="icon-left pt-1">
                      <span className="fa fa-arrow-right" />
                    </div>
                    <div className="icon-text-left">
                      <h3 className>
                        <Link to="/Getquote">Request For Quote</Link>{" "}
                      </h3>
                      <p className="mt-2">
                      Maximize Your Profit: Our logistics expertise can help you maximize profits. Start by sharing your details in the Getquote form.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="float-top">
                  <Link to="/Trading">
                    <img
                      src="assets/images/Food & Beverages.jpg"
                      className="img-responsive"
                      alt=""
                    />
                  </Link>
                  <div className="float-lt feature-gd">
                    <h3>
                      <Link to="/Trading">Food & Beverages</Link>{" "}
                    </h3>

                    <Link
                      className="btn btn-secondary btn-theme4 mt-4"
                      to="/Trading"
                    >
                      {" "}
                      More Details
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="float-top">
                  <Link to="/Trading">
                    <img
                      src="assets/images/Pharma.jpg"
                      className="img-responsive"
                      alt=""
                    />
                  </Link>
                  <div className="float-lt feature-gd">
                    <h3>
                      <Link to="/Trading">Pharma</Link>{" "}
                    </h3>

                    <Link
                      className="btn btn-secondary btn-theme4 mt-4"
                      to="/Trading"
                    >
                      {" "}
                      More Details
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="float-top">
                  <Link to="/Trading">
                    <img
                      src="assets/images/Chemicals & Petrochemicals.jpg"
                      className="img-responsive"
                      alt=""
                    />
                  </Link>
                  <div className="float-lt feature-gd">
                    <h3>
                      <Link to="/Trading">Chemicals & Petrochemicals</Link>{" "}
                    </h3>

                    <Link
                      className="btn btn-secondary btn-theme4 mt-4"
                      to="/Trading"
                    >
                      {" "}
                      More Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* //features */}
      </section>

      <section className="news-1" id="blog">
        <div className="news py-5">
          <div className="container py-md-3">
            <div className="heading text-center mx-auto">
              <h3 className="head">Our Freight Forwarding Services</h3>
             
            </div>
            <div className="blog-grids row mt-5">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Air">
                    <figure>
                      <img
                        src="assets/images/Air Freight.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <h3>
                      <Link to="/Air">Air Freight</Link>{" "}
                    </h3>
                    <p>  {companyname} is a well-known air freight forwarder with a
                      specialization in handling perishable and general cargo.
                      We have earned a strong reputation for providing efficient
                      and dependable air freight services.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Sea">
                    <figure>
                      <img
                        src="assets/images/Sea Freight.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <h3>
                      <Link to="/Sea">Sea Freight</Link>{" "}
                    </h3>
                    <p>  {companyname} is a prominent player in the global
                      ocean freight industry. Our strength lies in delivering customized
                      ocean freight solutions that lead to economic success for
                      our esteemed clients. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Rail">
                    <figure>
                      <img
                        src="assets/images/Rail Freight.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <h3>
                      <Link to="/Rail">Rail Freight</Link>{" "}
                    </h3>
                    <p>  {companyname} is a prominent player in the global ocean
                      freight industry, operating across key seaports worldwide.
                      Our strength lies in delivering customized ocean freight
                      solutions that lead to economic success for our esteemed
                      clients. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Surface">
                    <figure>
                      <img
                        src="assets/images/Surface Freight.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <h3>
                      <Link to="/Surface">Surface Freight</Link>{" "}
                    </h3>
                    <p>  {companyname} emerges as a distinguished player in the
                      realm of surface transportation, seamlessly connecting
                      vital destinations. Our expertise lies in delivering
                      efficient and customized surface freight solutions that
                      translate into tangible benefits for our esteemed clients. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Customs">
                    <figure>
                      <img
                        src="assets/images/Customs Clearance.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <h3>
                      <Link to="/Customs">Customs Clearance</Link>{" "}
                    </h3>
                    <p> For businesses deeply involved in import and export
                      operations, the complexities of customs clearance can be
                      both intricate and time-consuming. At {companyname}, we
                      understand the paramount importance of facilitating smooth
                      cargo movement across international borders. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Warehousing">
                    <figure>
                      <img
                        src="assets/images/Warehousing Service.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <h3>
                      <Link to="/Warehousing">Warehousing Service</Link>{" "}
                    </h3>
                    <p>  In today's rapidly evolving business landscape,
                      organizations of your caliber require a heightened focus
                      on managing work-in-progress goods, moving beyond the
                      traditional emphasis on finished products. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
