import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Sea from "./pages/Sea";
import Air from "./pages/Air";
import Surface from "./pages/Surface";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Warehousing from "./pages/Warehousing";
import Dangerous from "./pages/Dangerous";
import Customs from "./pages/Customs";
import Trading from "./pages/Trading";
import Hazardous from "./pages/Hazardous";
import Rail from "./pages/Rail";
import Careers from "./pages/Careers";


import Project from "./pages/Project";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Sea" element={<Sea />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Surface" element={<Surface />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Dangerous" element={<Dangerous />} />
          <Route path="/Customs" element={<Customs />} />
          <Route path="/Trading" element={<Trading />} />
          <Route path="/Hazardous" element={<Hazardous />} />
          <Route path="/Rail" element={<Rail />} />

    
        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
