import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <section className="w3l-contact-breadcrum">
        <div className="breadcrum-bg">
          <div className="container py-5">
            <p>
              <Link to="/Home">Home</Link> &nbsp; / &nbsp; Contact
            </p>
            <h2 className="my-3">Contact Us</h2>
           
          </div>
        </div>
      </section>
      {/* contact form */}
      <section className="w3l-contacts-2" id="contact">
        <div className="contacts-main">
          <div className="contant11-top-bg py-5">
            <div className="container py-md-3">
              <div className="d-grid contact section-gap">
                <div className="contact-info-left d-grid text-center">
                 
                  <div className="contact-info">
                    <span className="fa fa-mobile" aria-hidden="true" />
                    <h4>Phone</h4>
                    <p>
                      {companynumber}
                    </p>
                   
                  </div>
                  <div className="contact-info">
                    <span className="fa fa-map-marker" aria-hidden="true" />
                    <h4>Address</h4>
                    <p>{companyaddress}</p>
                  </div>
                  <div className="contact-info">
                    <span className="fa fa-envelope-o" aria-hidden="true" />
                    <h4>Mail</h4>
                    <p>
                        {companyemail}
                    </p>
                   
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="form-41-mian pb-5">
            <div className="container pb-md-3">
              <div className="d-grid align-form-map">
                <div className="form-inner-cont">
                  <h3 className="cont-head">Send Message Us</h3>
                  <form
                    className="main-input"
                    id="contactForm"
                    action="/php/thankyou-contact.php "
                    method="post"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            className="form-control"
                            onChange={(event) => handleFnameChange(event)}
                            type="text"
                            id="fname"
                            name="fname"
                            value={fname}
                            placeholder="Enter your first name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            className="form-control"
                            onChange={(event) => handlelnameChange(event)}
                            type="text"
                            id="lname"
                            name="lname"
                            value={lname}
                            placeholder="Enter your last name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            className="form-control"
                            onChange={(event) => handleemailChange(event)}
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            placeholder="Enter your Email"
                            required
                          />
                        </div>
                      </div>{" "}
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            className="form-control"
                            onChange={(event) => handlesubjectChange(event)}
                            type="text"
                            id="subject"
                            name="subject"
                            value={subject}
                            placeholder="Enter subject"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <textarea
                            className="form-control"
                            id="message"
                            name="message"
                            rows={1}
                            placeholder="Enter Message"
                            defaultValue={""}
                            value={message}
                            onChange={(event) => handlemessageChange(event)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-theme3"
                          type="submit"
                          defaultValue=""
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                  <h5 style={{ color: "red" }}>{result}</h5>
                </div>
                <div className="contact-right">
                 
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.7735689581978!2d73.00748737198079!3d19.073690612574623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c128fc5c95eb%3A0x636be332e6abcb99!2sMayuresh%20Trade%20Centre!5e0!3m2!1sen!2sin!4v1694867429956!5m2!1sen!2sin"
                    frameBorder={0}
                    style={{ border: 0 }}
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* //contact form */}
      <Footer />
    </>
  );
}
