import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-contact-breadcrum">
        <div className="breadcrum-bg">
          <div className="container py-5">
            <p>
              <Link to="/Home">Home</Link> &nbsp; / &nbsp; Surface Freight
            </p>
            <h2 className="my-3">Surface Freight</h2>
          </div>
        </div>
      </section>
      <section className="news-1" id="blog">
        <div className="news py-5">
          <div className="container py-md-3">
            <div className="heading text-center mx-auto">
              <h3 className="head">Surface Freight</h3>
            </div>
            <div className="blog-grids row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Air">
                    <figure>
                      <img
                        src="assets/images/Surface Freight.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <p>
                      {companyname} emerges as a distinguished player in the
                      realm of surface transportation, seamlessly connecting
                      vital destinations. Our expertise lies in delivering
                      efficient and customized surface freight solutions that
                      translate into tangible benefits for our esteemed clients.
                      Powered by a robust network and a seasoned workforce, we
                      ensure the fluid movement of goods and a range of
                      solutions that cater to the distinct needs of every
                      client.
                    </p>

                    <p>
                      One of our core strengths lies in our adept handling of a
                      variety of cargo types, ranging from general goods to
                      specialized shipments. Recognizing the uniqueness of each
                      cargo, we offer tailored solutions to ensure safe and
                      timely delivery. Our skilled team is well-equipped to
                      tackle any challenges that may arise during the
                      transportation process.
                    </p>

                    <p>
                      At {companyname}, we take immense pride in offering
                      comprehensive services that cover the entire spectrum of
                      surface transportation. Whether it's road or rail, our
                      offerings encompass seamless movement for both domestic
                      and international shipments. Our expansive service network
                      spans various regions, ensuring that your cargo reaches
                      its destination efficiently and on time.
                    </p>

                    <p>
                      Our strong network of partners and carriers empowers us to
                      provide a range of surface freight services that
                      prioritize reliability, flexibility, and
                      cost-effectiveness. Our prowess extends to handling
                      different types of cargo, ensuring that each consignment
                      is treated with the utmost care and precision.
                    </p>

                    <p>
                      {companyname} is committed to delivering personalized
                      attention to each client. Our dedicated team of
                      professionals collaborates closely with clients to
                      understand their unique transportation needs, enabling us
                      to provide tailored solutions that align perfectly with
                      their requirements. We offer real-time tracking and
                      updates to keep our clients informed about their
                      shipment's progress. Our team is available around the
                      clock to address any queries or concerns.
                    </p>

                    <p>
                      We understand that navigating the world of transportation
                      can be complex, especially for businesses new to the
                      industry. To assist our clients, we offer consultation
                      services that provide insights into the transportation
                      process. Our experts offer guidance on documentation,
                      regulatory compliance, and other aspects, ensuring our
                      clients are well-prepared for the journey.
                    </p>

                    <p>
                      Transparency and competitive pricing are the cornerstones
                      of {companyname}'s philosophy. Our pricing structure
                      reflects our commitment to providing high-quality services
                      at reasonable costs, fostering enduring relationships with
                      our clients.
                    </p>

                    <p>
                      {companyname} stands as a dynamic force in the surface
                      transportation arena, seamlessly connecting destinations
                      across the map. Our proficiency in tailored surface
                      freight solutions results in tangible benefits for our
                      clients. With a formidable network and expert insights, we
                      ensure the smooth movement of goods and a suite of
                      solutions that cater to every client's unique needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
