import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-contact-breadcrum">
        <div className="breadcrum-bg">
          <div className="container py-5">
            <p>
              <Link to="/Home">Home</Link> &nbsp; / &nbsp; Business Trade
            </p>
            <h2 className="my-3">Business Trade</h2>
          </div>
        </div>
      </section>
      {/* About Start */}
      <div className="w3l-about1 py-5" id="about">
        <div className="container py-lg-3">
          <div className="aboutgrids row">
            <div className="col-lg-6 aboutgrid2">
              <img
                src="assets/images/Apparel.jpg"
                alt="about image"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 aboutgrid1 mt-lg-0 mt-4 pl-lg-5">
              <h1>Apparel</h1>

              <p>
                India, situated at the heart of cotton and textile production,
                serves as a rich source of raw materials for the apparel
                industry. Amidst this vibrant landscape, we position ourselves
                as catalysts, dedicated to empowering mill owners, producers,
                and wholesalers in efficiently navigating the global trade of
                these valuable textile resources. Our mission resonates as we
                facilitate the seamless import and export of these essential
                commodities to every corner of the world. At our core, we assume
                the role of guardians, meticulously overseeing the intricate
                global distribution network of textiles, apparel, and
                accessories.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w3l-about1 py-5" id="about">
        <div className="container py-lg-3">
          <div className="aboutgrids row">
            <div className="col-lg-6 aboutgrid1 mt-lg-0 mt-4 pl-lg-5">
              <h1>Wood</h1>

              <p>
                India shines as an exceptional exporter, particularly in the
                realm of wood products. The art of wood trading unveils a wide
                array of offerings tailored to diverse market segments. This
                trade relies on a deep understanding of market intricacies,
                enabling the crafting of finished products of unmatched quality,
                finely attuned to the specific demands of their intended market.
                Within this intricate landscape, our organization emerges as a
                seasoned player, specializing in the trading of logs, lumber,
                and woodchips sourced from lush forests. Our expertise extends
                beyond the transactional aspect; it encompasses the entire
                tapestry of the trade process, from the initial harvest to the
                ultimate supply to end-users.
              </p>
            </div>
            <div className="col-lg-6 aboutgrid2">
              <img
                src="assets/images/Wood.jpg"
                alt="about image"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w3l-about1 py-5" id="about">
        <div className="container py-lg-3">
          <div className="aboutgrids row">
            <div className="col-lg-6 aboutgrid2">
              <img
                src="assets/images/Food & Beverages.jpg"
                alt="about image"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 aboutgrid1 mt-lg-0 mt-4 pl-lg-5">
              <h1>Food & Beverages</h1>

              <p>
                To satisfy the global appetite for delightful beverages,
                producers and distributors must ensure the swift and efficient
                flow of these products. At {companyname}, we deliver prompt and
                reliable transportation solutions to diverse destinations.
                Regardless of the nature of the beverages being transported, our
                experts are poised to provide the seamless support necessary for
                your fluid supply chain needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w3l-about1 py-5" id="about">
        <div className="container py-lg-3">
          <div className="aboutgrids row">
            <div className="col-lg-6 aboutgrid1 mt-lg-0 mt-4 pl-lg-5">
              <h1>Pharma</h1>

              <p>
                Within the vast expanse of India's pharmaceutical sector, a
                resilient and firmly established market thrives, offering a
                welcoming environment to a multitude of global enterprises.
                Despite the availability of a diverse range of products, the
                pricing framework remains rational. This convergence of elements
                serves as evidence of the substantial presence and
                sustainability of the Indian pharmaceutical market. It is within
                this dynamic context that we step forward as facilitators,
                providing a gateway to acquire top-notch pharmaceutical products
                from internationally renowned companies.
              </p>
            </div>
            <div className="col-lg-6 aboutgrid2">
              <img
                src="assets/images/Pharma.jpg"
                alt="about image"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w3l-about1 py-5" id="about">
        <div className="container py-lg-3">
          <div className="aboutgrids row">
            <div className="col-lg-6 aboutgrid2">
              <img
                src="assets/images/Chemicals & Petrochemicals.jpg"
                alt="about image"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 aboutgrid1 mt-lg-0 mt-4 pl-lg-5">
              <h1>Chemicals & Petrochemicals</h1>

              <p>
                With meticulous attention, stringent standards, and unwavering
                procedures, {companyname} ensures the safe conveyance of
                hazardous and potentially harmful materials, including chemicals
                and petrochemicals. As seasoned authorities in transporting
                dangerous cargo, {companyname} pledges the secure delivery of
                your consignments while adhering to relevant regulations.
                Moreover, we are prepared to assist you in handling unforeseen
                contingencies, regardless of your shipping destination across
                the globe.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
