import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-contact-breadcrum">
        <div className="breadcrum-bg">
          <div className="container py-5">
            <p>
              <Link to="/Home">Home</Link> &nbsp; / &nbsp; Customs Clearance
            </p>
            <h2 className="my-3">Customs Clearance</h2>
          </div>
        </div>
      </section>
      <section className="news-1" id="blog">
        <div className="news py-5">
          <div className="container py-md-3">
            <div className="heading text-center mx-auto">
              <h3 className="head">Customs Clearance</h3>
            </div>
            <div className="blog-grids row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Air">
                    <figure>
                      <img
                        src="assets/images/Customs Clearance.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <p>
                      For businesses deeply involved in import and export
                      operations, the complexities of customs clearance can be
                      both intricate and time-consuming. At {companyname}, we
                      understand the paramount importance of facilitating smooth
                      cargo movement across international borders. Therefore, we
                      offer dependable and efficient customs clearance services.
                    </p>

                    <p>
                      As a licensed Customs House Agent (CHA), {companyname}{" "}
                      possesses profound familiarity with the intricate
                      regulatory requirements and procedures involved in customs
                      clearance. Our team of experts demonstrates expertise in
                      managing various types of shipments, encompassing air,
                      ocean, and surface transportation.
                    </p>

                    <p>
                      Above all, our clients take precedence, and our customs
                      clearance services reflect our unwavering commitment to
                      delivering unparalleled customer satisfaction. We provide
                      a wide spectrum of customs clearance solutions, including
                      imports and exports, document preparation, customs
                      documentation, cargo inspection, and more. Businesses
                      entrusting their cargo to us can be confident that it will
                      navigate customs without delays or obstacles.
                    </p>

                    <p>
                      {companyname} recognizes that each business has unique
                      requirements, prompting us to develop tailored solutions
                      aligned with their specific demands. Our skilled
                      professionals collaborate closely with clients, capturing
                      their specific needs and providing personalized strategies
                      that streamline their customs clearance process.
                    </p>

                    <p>
                      Our customs clearance expertise extends beyond
                      geographical boundaries. Supported by a global network of
                      partners and agents, we deliver customs clearance services
                      in prominent nations worldwide. This extensive reach
                      enables us to handle a wide range of shipment categories,
                      including e-commerce entities, SMEs, and prominent
                      enterprises and brands.
                    </p>

                    <p>
                      Our commitment covers comprehensive customs clearance
                      solutions, spanning the entire process from pre-clearance
                      to post-clearance stages. We ensure meticulous preparation
                      and timely submission of required documents while adhering
                      rigorously to customs regulations and procedures.
                    </p>

                    <p>
                      Beyond customs clearance, we offer a diverse suite of
                      value-added services to enhance the overall transportation
                      experience. Our offerings include freight forwarding,
                      warehousing, packaging, and transportation insurance.
                    </p>

                    <p>
                      {companyname} takes center stage as the trusted partner
                      for businesses in search of reliable and efficient customs
                      clearance services. With our extensive experience, global
                      reach, and customer-centric ethos, we provide tailored
                      solutions that optimize the customs clearance journey,
                      ensuring smooth customs clearance for shipments.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
