import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-contact-breadcrum">
        <div className="breadcrum-bg">
          <div className="container py-5">
            <p>
              <Link to="/Home">Home</Link> &nbsp; / &nbsp; Warehousing Service
            </p>
            <h2 className="my-3">Warehousing Service</h2>
          </div>
        </div>
      </section>
      <section className="news-1" id="blog">
        <div className="news py-5">
          <div className="container py-md-3">
            <div className="heading text-center mx-auto">
              <h3 className="head">Warehousing Service</h3>
            </div>
            <div className="blog-grids row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Air">
                    <figure>
                      <img
                        src="assets/images/Warehousing Service.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <p>
                      In today's rapidly evolving business landscape,
                      organizations of your caliber require a heightened focus
                      on managing work-in-progress goods, moving beyond the
                      traditional emphasis on finished products. At{" "}
                      {companyname}, we offer a diverse range of solutions
                      designed to optimize your time and space allocation
                      through proficient warehouse management. Our spectrum of
                      services covers Intracity and Intercity needs,
                      encompassing everything from local warehousing to
                      international and domestic courier services. Warehouse
                      management, aptly regarded as the backbone and cornerstone
                      of our logistics management system, plays a pivotal role
                      in this holistic approach.
                    </p>

                    <p>
                      {companyname} is recognized as a reputable authority in
                      warehousing, catering to both import and export shipments.
                      Our extensive network of partners enables us to develop
                      specialized solutions for temperature-controlled cargo. We
                      take responsibility for inventory management, providing
                      services such as repackaging and palletization to ensure
                      the smooth movement of goods.
                    </p>

                    <p>
                      Our warehousing services transcend conventional storage,
                      embodying an integrated approach that seamlessly aligns
                      with your operational needs. {companyname} is committed to
                      optimizing your inventory processes, enhancing efficiency,
                      and contributing to the overall operational excellence of
                      your organization. With our warehouse management system,
                      you can anticipate greater agility, improved control, and
                      a comprehensive approach to your storage requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
