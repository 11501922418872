import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-contact-breadcrum">
        <div className="breadcrum-bg">
          <div className="container py-5">
            <p>
              <Link to="/Home">Home</Link> &nbsp; / &nbsp; Sea Freight
            </p>
            <h2 className="my-3">Sea Freight</h2>
          </div>
        </div>
      </section>
      <section className="news-1" id="blog">
        <div className="news py-5">
          <div className="container py-md-3">
            <div className="heading text-center mx-auto">
              <h3 className="head">Sea Freight</h3>
            </div>
            <div className="blog-grids row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Air">
                    <figure>
                      <img
                        src="assets/images/Sea Freight.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <p>
                      {companyname} is a prominent player in the global
                      ocean freight industry. Our strength lies in delivering customized
                      ocean freight solutions that lead to economic success for
                      our esteemed clients. Supported by a robust agency network
                      and a highly experienced workforce, we ensure smooth
                      operations and a wide range of solutions that meet the
                      specific demands of each client.
                    </p>

                    <p>
                      One of our core strengths lies in our ability to handle
                      heavy lift, over-dimensional cargos, and breakbulk cargos.
                      Recognizing the uniqueness of each cargo and its specific
                      handling requirements, we offer tailored solutions to
                      guarantee the safe and timely delivery of your shipments.
                      Our skilled team is proficient in addressing any
                      challenges that may arise during the complex shipping
                      process.
                    </p>

                    

                    <p>
                      Our extensive agency network spans major seaports
                      worldwide. Strategic partnerships with leading carriers
                      and esteemed shipping lines enable us to offer a wide
                      range of ocean freight services characterized by
                      reliability, flexibility, and cost-effectiveness. Whether
                      it's full-container-load (FCL), less-than-container-load
                      (LCL), or breakbulk shipments, our expertise covers
                      various cargo types.
                    </p>

                    <p>
                      {companyname} is dedicated to providing
                      personalized client attention. Our committed team of
                      professionals collaborates closely with each client to
                      understand their unique shipping needs, offering
                      tailor-made solutions that align perfectly with their
                      requirements. We provide real-time tracking and updates to
                      keep our clients well-informed about the progress of their
                      shipments. Our team is available around the clock to
                      address any queries or concerns.
                    </p>

                    <p>
                      We recognize that the shipping industry can appear complex
                      and challenging, especially for businesses new to it.
                      Therefore, we offer consultation services to empower our
                      clients with a comprehensive understanding of the shipping
                      process. Our experts provide expert guidance on
                      documentation, customs clearance, and other regulatory
                      aspects, ensuring our clients are well-prepared to
                      navigate the shipping journey with confidence.
                    </p>

                    <p>
                      Transparency and competitive pricing are the cornerstones
                      of {companyname}'s philosophy. Our pricing
                      structure reflects our commitment to building enduring
                      relationships with our clients. We believe in delivering
                      top-notch services at a reasonable cost, underscoring our
                      dedication to providing quality and value.
                    </p>

                    <p>
                      {companyname} remains a prominent player in the
                      global ocean freight industry, serving major seaports
                      worldwide. Our specialization in customized ocean freight
                      solutions leads to economic success for our clients. With
                      a robust agency network and seasoned expertise, we ensure
                      seamless operations and diverse solutions tailored to each
                      client's unique requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
