import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
 useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };

  return (
    <>
      <section className="w3l-bootstrap-header">
        <nav className="navbar navbar-expand-lg navbar-light py-lg-3 py-2">
          <div className="container">
            <Link class="navbar-brand" to="#index.html">
              <img
                src="assets/images/logo.png"
                alt="Your logo"
                title="Your logo"
                style={{ height: "80px" }}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa fa-bars" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/About">
                    About Company
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Our Services
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/Air">
                      {" "}
                      Air Freight{" "}
                    </Link>
                    <Link className="dropdown-item" to="/Sea">
                      {" "}
                      Sea Freight{" "}
                    </Link>
                    <Link className="dropdown-item" to="/Rail">
                      {" "}
                      Rail Freight{" "}
                    </Link>
                    <Link className="dropdown-item" to="/Surface">
                      {" "}
                      Surface Freight{" "}
                    </Link>
                    <Link className="dropdown-item" to="/Customs">
                      {" "}
                      Customs Clearance{" "}
                    </Link>
                    <Link className="dropdown-item" to="/Warehousing">
                      Warehousing Service{" "}
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Business Trade
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/Trading">
                    Apparel
                    </Link>
                    <Link className="dropdown-item" to="/Trading">
                    Wood{" "}
                    </Link>
                    <Link className="dropdown-item" to="/Trading">
                    Food & Beverages{" "}
                    </Link>
                    <Link className="dropdown-item" to="/Trading">
                    Pharma{" "}
                    </Link>
                    <Link className="dropdown-item" to="/Trading">
                    Chemicals & Petrochemicals{" "}
                    </Link>
                    
                  </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Contact">
                    Contact Us
                  </Link>
                </li>
              </ul>
              <Link className="btn btn-secondary btn-theme2" to="/Getquote">
                {" "}
                Request For Quote
              </Link>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};

export default Header;
