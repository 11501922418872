import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="w3l-contact-breadcrum">
        <div className="breadcrum-bg">
          <div className="container py-5">
            <p>
              <Link to="/Home">Home</Link> &nbsp; / &nbsp; Air Freight
            </p>
            <h2 className="my-3">Air Freight</h2>
          </div>
        </div>
      </section>
      <section className="news-1" id="blog">
        <div className="news py-5">
          <div className="container py-md-3">
            <div className="heading text-center mx-auto">
              <h3 className="head">Air Freight</h3>
            </div>
            <div className="blog-grids row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="blog-grid" id="zoomIn">
                  <Link to="/Air">
                    <figure>
                      <img
                        src="assets/images/Air Freight.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </figure>
                  </Link>
                  <div className="blog-info">
                    <p>
                      {companyname} is a well-known air freight forwarder with a
                      specialization in handling perishable and general cargo.
                      We have earned a strong reputation for providing efficient
                      and dependable air freight services.
                    </p>

                    <p>
                      Our expertise truly shines when it comes to managing
                      frozen-temperature-controlled shipments, which encompass
                      pharmaceuticals, vaccines, bulk drugs, vegetables, fruits,
                      flowers, electronics/machinery, garments, and tissue
                      culture. This unique proficiency sets us apart in the
                      industry. Recognizing the paramount importance of handling
                      such shipments with the utmost care and precision, we have
                      meticulously assembled a team of experts who are
                      proficient in managing all varieties of perishable goods.
                    </p>

                    <p>
                      At {companyname}, we firmly believe in the individuality
                      of each client and steer clear of the one-size-fits-all
                      approach to air freight solutions. We take pride in
                      offering customized solutions meticulously designed to
                      cater to the distinctive needs of every client. Our
                      dedicated team of professionals is unwaveringly committed
                      to delivering innovative and adaptable air freight
                      solutions that set new standards in the industry.
                    </p>

                    <p>
                      Our affiliation with IATA and partnerships with over 100
                      airlines and charter operators enable us to provide an
                      array of air freight services that are not only flexible
                      and reliable but also cost-effective. We work
                      collaboratively with our partners to ensure that we
                      deliver the most optimal air freight solutions to our
                      clients. Our comprehensive services encompass door-to-door
                      delivery, charter flights, temperature-controlled
                      shipments, and more.
                    </p>

                    <p>
                      <strong>Door-to-Door Delivery:</strong> Understanding the
                      significance of punctual delivery, our door-to-door
                      service guarantees that your cargo is collected from its
                      origin and directly delivered to its destination. We
                      manage all facets of the delivery process, including
                      customs clearance and final-mile delivery.
                    </p>

                    <p>
                      <strong>Charter Flights:</strong> For time-sensitive or
                      high-value shipments, our charter flight service offers a
                      swift and dependable solution. Collaborating with our
                      partners, we arrange dedicated aircraft to transport your
                      cargo, ensuring swift and secure arrival at its
                      destination.
                    </p>

                    <p>
                      <strong>Temperature-Controlled Shipments:</strong> We
                      excel in handling temperature-controlled shipments,
                      encompassing pharmaceuticals, vaccines, bulk drugs,
                      vegetables, fruits, flowers, electronics/machinery,
                      garments, and tissue culture. We comprehend the
                      criticality of preserving the cold chain during transit
                      and have invested in cutting-edge facilities and equipment
                      to guarantee that your cargo remains at the requisite
                      temperature throughout its journey.
                    </p>

                    <p>
                      In addition to our core offerings, we extend an array of
                      value-added services to ensure a seamless air freight
                      process. These services encompass warehousing, customs
                      clearance, documentation, insurance, and more.
                    </p>

                    <p>
                      At {companyname}, we are resolute in delivering dependable
                      and effective air freight solutions tailored to each
                      client's unique requirements. Our team of experts is
                      perpetually available to offer insights and guidance on
                      the optimal logistics solutions for your business. Get in
                      touch with us today to discover how we can assist you with
                      your air freight needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
